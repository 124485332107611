export const PatchList = 
    [
        { type: 'Hotfix', number: '4.2.2.2', date: new Date('March 21, 2021') },
        { type: 'Hotfix', number: '4.2.2.3', date: new Date('March 23, 2021') },
        { type: 'Update', number: '4.3', date: new Date('April 20, 2021') },
        { type: 'Hotfix', number: '4.3.0.1', date: new Date('April 20, 2021') },
        { type: 'Hotfix', number: '4.3.0.2', date: new Date('April 20, 2021') },
        { type: 'Hotfix', number: '4.3.0.3', date: new Date('April 20, 2021') },
        { type: 'Hotfix', number: '4.3.0.4', date: new Date('April 20, 2021') },
        { type: 'Hotfix', number: '4.3.0.5', date: new Date('April 29, 2021') },
        { type: 'Hotfix', number: '4.3.0.6', date: new Date('May 5, 2021') },
        { type: 'Hotfix', number: '4.3.0.7', date: new Date('May 10, 2021') },
        { type: 'Update', number: '4.4', date: new Date('June 3, 2021') },
        { type: 'Hotfix', number: '4.4.0.1', date: new Date('June 4, 2021') },
        { type: 'Hotfix', number: '4.4.0.2', date: new Date('June 10, 2021') },
        { type: 'Hotfix', number: '4.4.0.3', date: new Date('June 11, 2021') },
        { type: 'Hotfix', number: '4.4.1', date: new Date('June 29, 2021') },
        { type: 'Hotfix', number: '4.4.2', date: new Date('October 28, 2021') },
        { type: 'Hotfix', number: '4.4.2.1', date: new Date('November 7, 2021') },
        { type: 'Update', number: '4.5.0', date: new Date('December 10, 2021') },
        { type: 'Hotfix', number: '4.5.1', date: new Date('December 15, 2021') },
        { type: 'Update', number: '4.6.0', date: new Date('March 15, 2022') },
        { type: 'Hotfix', number: '4.6.1', date: new Date('March 16, 2022') },
        { type: 'Hotfix', number: '4.6.2', date: new Date('March 17, 2022') },
        { type: 'Hotfix', number: '4.6.3', date: new Date('March 18, 2022') },
        { type: 'Hotfix', number: '4.6.4', date: new Date('March 24, 2022') },
        { type: 'Update', number: '5.1.0', date: new Date('October 19, 2023') },
        { type: 'Update', number: '5.1.1', date: new Date('October 20, 2023') },
        { type: 'Update', number: '5.1.2', date: new Date('October 24, 2023') },
        { type: 'Update', number: '5.1.3', date: new Date('October 26, 2023') },
        { type: 'Update', number: '5.1.4', date: new Date('November 1, 2023') },
        { type: 'Update', number: '5.1.5', date: new Date('November 3, 2023') },
        { type: 'Update', number: '5.2.0', date: new Date('December 13, 2023') },
        { type: 'Update', number: '5.2.1', date: new Date('December 19, 2023') },
        { type: 'Update', number: '5.2.2', date: new Date('December 20, 2023') },
        { type: 'Update', number: '5.2.3', date: new Date('February 6, 2024') }
    ]
